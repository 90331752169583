/**
 * Case Api
 * 1.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.RequestOpts = {
    baseUrl: "/",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type CasePartyModel = {
    partyName?: string | null;
    partyEmail?: string | null;
    partyRoleCode?: PartyRoleCode;
    partyRoleDescription?: string | null;
    partyPhone?: string | null;
    partyCellphone?: string | null;
    partyDisplayPhoneNumber?: string | null;
    partyIsPrimary?: boolean;
    nameType?: string | null;
    nameKey?: string | null;
    nameCode?: string | null;
    partyCRMGuid?: string | null;
    primaryPartyCVR?: string | null;
};
export type CasePhaseModel = {
    casePhaseDescription?: string | null;
    casePhaseRank?: number;
    casePhaseCode?: CasePhaseCode;
    phaseResponsibleCode?: string | null;
    phaseResponsibleName?: string | null;
    phaseParticipant1Code?: string | null;
    phaseParticipant1Name?: string | null;
    phaseParticipant2Code?: string | null;
    phaseParticipant2Name?: string | null;
    phaseStateCode?: CasePhaseState;
    phaseStateDescription?: string | null;
    phaseStateRank?: number;
};
export type NotificationModel = {
    notificationId?: string | null;
    parentNotificationId?: string | null;
    notificationGroupCode?: NotificationGroup;
    notificationGroupDescription?: string | null;
    readTime?: string | null;
    notificationTriggerDescription?: string | null;
    notificationTriggerSummary?: string | null;
    notificationGroupSummary?: string | null;
    notificationTriggerCode?: NotificationTrigger;
    recipientCode?: string | null;
    recipientSummary?: string | null;
    recipientName?: string | null;
    recipientOrgUnitCode?: string | null;
    recipientOrgUnitSummary?: string | null;
    recipientOrgUnitName?: string | null;
    actor?: string | null;
    casePhaseCode?: CasePhaseCode;
    casePhaseSummary?: string | null;
    casePhaseDescription?: string | null;
    eventCode?: string | null;
    eventDescription?: string | null;
    createDatetime?: string | null;
    updateDatetime?: string | null;
    fileKey?: string | null;
    eventSummary?: string | null;
};
export type CaseCalculationModel = {
    id?: number;
    calculationPath?: string | null;
    displayId?: number | null;
    isActive?: boolean;
    calculationTimestamp?: string | null;
    repayAmount?: number | null;
    eventDueDate?: string | null;
    changeMethod?: ChangeMethodCode;
    eventDescription?: EventDescriptionCode;
    currencyCode?: CurrencyCode;
    isAgreementCandidateCode?: boolean;
};
export type CaseNoteModel = {
    caseNoteId?: string | null;
    noteTypeCode?: NoteType;
    noteTypeDescription?: string | null;
    noteText?: string | null;
    createUserName?: string | null;
    createDateTime?: string;
    updateUserName?: string | null;
    updateDateTime?: string | null;
};
export type CaseRecordModel = {
    recordId?: string | null;
    recordKey?: string | null;
    title?: string | null;
    recordExtension?: string | null;
    recordTypeDescription?: string | null;
    recordTypeCode?: RecordType;
    recordGroupDescription?: string | null;
    recordGroupCode?: RecordGroup;
    casePhaseCode?: CasePhaseCode;
    casePhaseDescription?: string | null;
    createDate?: string;
    documentHierarchyCode?: string | null;
    primaryRecordKey?: string | null;
    isActive?: boolean;
    recordSize?: number;
    postalDatetime?: string | null;
    calcId?: string | null;
};
export type TimeMeasure = {
    years?: number;
    months?: number;
    days?: number;
};
export type EventReceiptModel = {
    id?: string | null;
    repayAmount?: number | null;
    eventDueDate?: string | null;
    compensationAmount?: number | null;
    timeToMaturityReduction?: TimeMeasure;
    newMaturityDate?: string | null;
    eventValueDate?: string | null;
    accruedInterest?: number | null;
    changeMethod?: ChangeMethodCode;
    newNotionalAmount?: number | null;
    paymentReductionAmount?: number | null;
    isOnTerm?: boolean;
    fileKey?: string | null;
    createDate?: string | null;
    updateDate?: string | null;
};
export type CaseModel = {
    caseId?: string | null;
    fileKey?: string | null;
    fileClassCode?: string | null;
    title?: string | null;
    contractId?: string | null;
    companyNumber?: string | null;
    caseStateDescription?: CaseStateCode;
    eventDescription?: EventDescriptionCode;
    displayAmount?: number | null;
    createDate?: string | null;
    createUserCode?: string | null;
    createUserName?: string | null;
    updateDateTime?: string | null;
    updateUserCode?: string | null;
    updateUserName?: string | null;
    closeDate?: string | null;
    closeReasonCode?: CloseReasonCode;
    closeReasonDescription?: string | null;
    caseHandlerName?: string | null;
    caseHandlerCode?: string | null;
    caseHandlerEmail?: string | null;
    caseHandlerPhone?: string | null;
    executingOrgUnitCode?: string | null;
    casePhaseCode?: CasePhaseCode;
    parties?: CasePartyModel[] | null;
    phases?: CasePhaseModel[] | null;
    notifications?: NotificationModel[] | null;
    calculations?: CaseCalculationModel[] | null;
    notes?: CaseNoteModel[] | null;
    caseRecords?: CaseRecordModel[] | null;
    eventReceipt?: EventReceiptModel;
};
export type ErrorResponse = {
    errorMessage?: string[] | null;
};
export type CaseModelLite = {
    caseId?: string | null;
    fileKey?: string | null;
    fileClassCode?: string | null;
    title?: string | null;
    contractId?: string | null;
    companyNumber?: string | null;
    caseStateDescription?: CaseStateCode;
    eventDescription?: EventDescriptionCode;
    displayAmount?: number | null;
    createDate?: string | null;
    createUserCode?: string | null;
    createUserName?: string | null;
    updateDateTime?: string | null;
    updateUserCode?: string | null;
    updateUserName?: string | null;
    closeDate?: string | null;
    closeReasonCode?: CloseReasonCode;
    closeReasonDescription?: string | null;
    caseHandlerName?: string | null;
    caseHandlerCode?: string | null;
    caseHandlerEmail?: string | null;
    caseHandlerPhone?: string | null;
    executingOrgUnitCode?: string | null;
    casePhaseCode?: CasePhaseCode;
    parties?: CasePartyModel[] | null;
    phases?: CasePhaseModel[] | null;
    notifications?: NotificationModel[] | null;
};
export type Assignee = {
    fullName?: string | null;
    partyRoleCode?: string | null;
    partyRoleDescription?: string | null;
    crmGuid?: string | null;
    samAccountName?: string | null;
};
export type CasePhase = {
    casePhaseCode?: CasePhaseCode;
    casePhaseCodeDescription?: string | null;
    assignees?: Assignee[] | null;
};
export type CasePhaseAssigneeModel = {
    assignees?: Assignee[] | null;
    casePhases?: CasePhase[] | null;
};
export type EventDescription = {
    text?: string | null;
    value?: EventDescriptionCode;
};
export type CreateCase = {
    eventType?: EventDescriptionCode;
    contractId?: string | null;
    companyContactId?: string | null;
    caseHandler?: string | null;
    calculationModel?: CaseCalculationModel;
};
export type CompanyContactModel = {
    companyContactId?: string | null;
    nameKey?: string | null;
    nameCode?: string | null;
    nameType?: string | null;
};
export type ClosingReasonModel = {
    closingReasonCode?: CloseReasonCode;
    closingReasonDescription?: string | null;
};
export type CloseCaseRequest = {
    closeReasonNoteText?: string | null;
    closingReasonCode?: CloseReasonCode;
};
export type CasePhaseStateChange = {
    casePhaseCode: CasePhaseCode;
    casePhaseState: CasePhaseState;
};
export type Note = {
    caseId?: string | null;
    noteTypeCode?: NoteType;
    noteText?: string | null;
};
export type CaseRecordStateChange = {
    recordId: string | null;
    isActive: boolean;
};
export type CaseCalculationStateChange = {
    calculationPath: string | null;
    isActive: boolean;
};
export type DocumentLabelChange = {
    recordId: string | null;
    recordGroupCode: RecordGroup;
    casePhaseCode: CasePhaseCode;
};
export type ProblemDetails = {
    "type"?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export type Document = {
    title?: string | null;
    recordExtension?: string | null;
    createDate?: string;
    pathToStorage?: string | null;
    calculationPath?: string | null;
};
export type Email = {
    subject?: string | null;
    bodyDocument?: Document;
    attachments?: Document[] | null;
    pathToStorage?: string | null;
    sentDateTime?: string;
};
export type CreateEmailDocument = {
    email?: Email;
    contractId?: string | null;
};
export type SentAttachment = {
    name: string | null;
    content: string | null;
    contentType: string | null;
    recordGroupCode: RecordGroup;
};
export type SentEmail = {
    subject: string | null;
    content: string | null;
    sentTime: string;
    attachments: SentAttachment[] | null;
    isExternal?: boolean;
};
export type CreateDocument = {
    documentContent?: string | null;
    mediaType?: string | null;
    recordExtension?: string | null;
    title?: string | null;
    recordTypeCode?: RecordType;
    recordStateCode?: string | null;
    recordGroupCode?: RecordGroup;
    responsibleOrgUnitCode?: string | null;
    isActiveCode?: string | null;
    caseCalculationId?: number | null;
    casePhaseCode?: CasePhaseCode;
    postalDatetime?: string | null;
    mainNoticeId?: string | null;
    sendStatus?: SendStatus;
};
export type EventReceipt = {
    maturityDate?: string | null;
    eventValueDate?: string | null;
    eventDueDate?: string | null;
    accruedInterest?: number;
    changeMethod?: ChangeMethodCode;
    compensationAmount?: number;
    notionalAmountOnValueDate?: number;
    paymentReduction?: number;
    repayAmount?: number;
    isOnTerm?: boolean;
    timeToMaturityReduction?: TimeMeasure;
};
export type PhaseResponsible = {
    caseHandlerName: string | null;
    casePhaseCode: CasePhaseCode;
};
export type CaseRecordIdPair = {
    caseId?: string | null;
    recordId?: string | null;
};
export type DocumentPartyChange = {
    recordId: string | null;
    nameKey: string | null;
    documentParty: DocumentParty;
};
export type DocumentSendStatusChange = {
    recordId: string | null;
    sendStatus: SendStatus;
};
/**
 * Get cases with fileClass = "070.080" for a contract with an option to only get active cases (implemented as cases where closedDate is null), valid input is "true" and "false"
 */
export function getContractByContractNumberCases(contractNumber: string, { active }: {
    active?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModel[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/contract/${encodeURIComponent(contractNumber)}/cases${QS.query(QS.explode({
        active
    }))}`, {
        ...opts
    }));
}
/**
 * Get case with fileClass = "070.070" for a contract
 */
export function getContractByContractNumber(contractNumber: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModel;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/contract/${encodeURIComponent(contractNumber)}`, {
        ...opts
    }));
}
/**
 * Get cases with fileClass = "070.080" for a contact with an option to only get active cases (implemented as cases where closedDate is null, valid input is "true" and "false"
 */
export function getContactByCompanyContactIdCases(companyContactId: string, { active }: {
    active?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModel[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/contact/${encodeURIComponent(companyContactId)}/cases${QS.query(QS.explode({
        active
    }))}`, {
        ...opts
    }));
}
/**
 * Get case with fileClass = "070.080" by caseId
 */
export function getCaseByCaseId(caseId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModel;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/case/${encodeURIComponent(caseId)}`, {
        ...opts
    }));
}
/**
 * Get all event cases supported by LoanPortal with fileClass = "070.080"
 */
export function getCases(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModelLite[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/cases", {
        ...opts
    }));
}
/**
 * Get case with fileClass = "070.080" by companyId (CRMGuid)
 */
export function getCaseByCompanyIdCases(companyId: string, { queryFilter }: {
    queryFilter?: QueryFilter;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModel[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/case/${encodeURIComponent(companyId)}/cases${QS.query(QS.explode({
        queryFilter
    }))}`, {
        ...opts
    }));
}
/**
 * Get case with fileClass = "070.080" by companyId (CRMGuid)
 */
export function getCaseByCompanyIdCaseslite(companyId: string, { queryFilter }: {
    queryFilter?: QueryFilter;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModelLite[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/case/${encodeURIComponent(companyId)}/caseslite${QS.query(QS.explode({
        queryFilter
    }))}`, {
        ...opts
    }));
}
/**
 * Returns a list of all participants on both case and phase level
 */
export function getCaseByCaseIdAssignees(caseId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CasePhaseAssigneeModel;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/case/${encodeURIComponent(caseId)}/assignees`, {
        ...opts
    }));
}
/**
 * Input is a eventDescription and a list of contractIds, will return a dictionary where the Key is the contractId and the Value is a boolean stating whether there are an active case of the selected eventDescription
 */
export function postCaseLoansgotactivecaseByEventDescription(eventDescription: EventDescriptionCode, body?: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            [key: string]: boolean;
        };
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/case/loansgotactivecase/${encodeURIComponent(eventDescription)}`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    })));
}
/**
 * Get all active loan portal cases, defined as cases with fileClass = "070.080" and AFTH = "EKSTAFD", "INDFRI" og "INDFRI_F"
 */
export function getCasesActive(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModel[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/cases/active", {
        ...opts
    }));
}
/**
 * Get all active loan portal cases, defined as cases with fileClass = "070.080" and AFTH = "EKSTAFD", "INDFRI" og "INDFRI_F"
 */
export function getCasesActivelite(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModelLite[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/cases/activelite", {
        ...opts
    }));
}
/**
 * Get event descriptions
 */
export function getCaseEventdescriptions(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: EventDescription[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/case/eventdescriptions", {
        ...opts
    }));
}
/**
 * Get next calculation display id
 */
export function getNextcalculationdisplayidByCaseId(caseId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: number;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/nextcalculationdisplayid/${encodeURIComponent(caseId)}`, {
        ...opts
    }));
}
/**
 * Updates the calculation status to deactivated
 */
export function postCalculationstatusByCalculationPath(calculationPath: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/calculationstatus/${encodeURIComponent(calculationPath)}`, {
        ...opts,
        method: "POST"
    }));
}
/**
 * InActivavate the calculation with the given calculationpath and create a new calculationModel where displaId and isAgreementCandidate is retained, other properties is updated according to input data
 */
export function postCalculationByCalculationPathUpdate(calculationPath: string, caseCalculationModel?: CaseCalculationModel, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/calculation/${encodeURIComponent(calculationPath)}/update`, oazapfts.json({
        ...opts,
        method: "POST",
        body: caseCalculationModel
    })));
}
/**
 * Create case, returns caseId(wz fileNo) for the new case
 */
export function postCase(createCase?: CreateCase, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: string;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/case", oazapfts.json({
        ...opts,
        method: "POST",
        body: createCase
    })));
}
/**
 * Get contact by company contact id (CRM Guid looked up in WZ).
 */
export function getContactByCompanyContactId(companyContactId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CompanyContactModel;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/contact/${encodeURIComponent(companyContactId)}`, {
        ...opts
    }));
}
/**
 * Update contact person on case to the contact with the CRMGuid given in newCompanyContactId
 */
export function putContactByCaseIdAndNewCompanyContactId(caseId: string, newCompanyContactId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/contact/${encodeURIComponent(caseId)}/${encodeURIComponent(newCompanyContactId)}`, {
        ...opts,
        method: "PUT"
    }));
}
/**
 * Get closing reasons for a case
 */
export function getCaseClosereasonsByCaseId(caseId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: ClosingReasonModel[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/case/closereasons/${encodeURIComponent(caseId)}`, {
        ...opts
    }));
}
/**
 * Create calculation, returns displayId for the calculation
 */
export function postCaseByCaseIdCalculation(caseId: string, caseCalculationModel?: CaseCalculationModel, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: number;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/case/${encodeURIComponent(caseId)}/calculation`, oazapfts.json({
        ...opts,
        method: "POST",
        body: caseCalculationModel
    })));
}
/**
 * Close a case by setting state_value in WZ to 'FB', setting case phases in progress to cancelled and invalidating all documents related to case
 */
export function putCaseByCaseIdClose(caseId: string, closeCaseRequest?: CloseCaseRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/case/${encodeURIComponent(caseId)}/close`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: closeCaseRequest
    })));
}
/**
 * Updates states for a number of case phases
 */
export function putCaseByCaseIdPhasestates(caseId: string, body?: CasePhaseStateChange[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/case/${encodeURIComponent(caseId)}/phasestates`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    })));
}
/**
 * Updates a caseState for a case
 */
export function putCaseByCaseIdCasestate(caseId: string, { change }: {
    change?: CaseStateCode;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/case/${encodeURIComponent(caseId)}/casestate${QS.query(QS.explode({
        change
    }))}`, {
        ...opts,
        method: "PUT"
    }));
}
/**
 * Gets notifications by contact id
 */
export function getContactByContactIdNotifications(contactId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: NotificationModel[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/contact/${encodeURIComponent(contactId)}/notifications`, {
        ...opts
    }));
}
/**
 * Marks a notification as read
 */
export function postNotificationByNotificationIdMarkasread(notificationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/notification/${encodeURIComponent(notificationId)}/markasread`, {
        ...opts,
        method: "POST"
    }));
}
/**
 * Deletes a calculation by caseId and displayId
 */
export function putCalculationByCaseIdDisplayidAndDisplayId(caseId: string, displayId: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/calculation/${encodeURIComponent(caseId)}/displayid/${encodeURIComponent(displayId)}`, {
        ...opts,
        method: "PUT"
    }));
}
/**
 * Creates a note
 */
export function postCaseNote(note?: Note, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText("/case/note", oazapfts.json({
        ...opts,
        method: "POST",
        body: note
    })));
}
/**
 * Upserts a note
 */
export function putCaseNote(note?: Note, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText("/case/note", oazapfts.json({
        ...opts,
        method: "PUT",
        body: note
    })));
}
/**
 * Set agreement candidate flag to false for the calculation that currently is agreement candidate
 */
export function putCalculationByCaseIdCancelagreementcandidate(caseId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/calculation/${encodeURIComponent(caseId)}/cancelagreementcandidate`, {
        ...opts,
        method: "PUT"
    }));
}
/**
 * Set agreement candidate flag to true for the calculation with given calculationPath
 */
export function postCalculationByCalculationPathMarkasagreementcandidate(calculationPath: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/calculation/${encodeURIComponent(calculationPath)}/markasagreementcandidate`, {
        ...opts,
        method: "POST"
    }));
}
/**
 * Updates isActiveCode for a number of case records/documents
 */
export function putDocumentActive(body?: CaseRecordStateChange[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText("/document/active", oazapfts.json({
        ...opts,
        method: "PUT",
        body
    })));
}
/**
 * Updates isActiveCode for a number of case calculations
 */
export function putCalculationActive(body?: CaseCalculationStateChange[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText("/calculation/active", oazapfts.json({
        ...opts,
        method: "PUT",
        body
    })));
}
/**
 * Updates case phase
 */
export function putCaseByCaseIdPhaseAndCasePhaseCode(caseId: string, casePhaseCode: CasePhaseCode, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/case/${encodeURIComponent(caseId)}/phase/${encodeURIComponent(casePhaseCode)}`, {
        ...opts,
        method: "PUT"
    }));
}
/**
 * Updates the event description of case
 */
export function putCaseByCaseIdEventDescriptionAndEventType(caseId: string, eventType: EventDescriptionCode, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/case/${encodeURIComponent(caseId)}/eventDescription/${encodeURIComponent(eventType)}`, {
        ...opts,
        method: "PUT"
    }));
}
/**
 * Updates the display amount of case
 */
export function putCaseByCaseIdDisplayamountAndAmount(caseId: string, amount: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/case/${encodeURIComponent(caseId)}/displayamount/${encodeURIComponent(amount)}`, {
        ...opts,
        method: "PUT"
    }));
}
/**
 * Label a document with an updated recordGroupCode and casePhaseCode
 */
export function putDocumentLabel(body?: DocumentLabelChange[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText("/document/label", oazapfts.json({
        ...opts,
        method: "PUT",
        body
    })));
}
/**
 * Reads a pdf rendition of a document by recordId
 */
export function getDocumentByRecordIdPdf(recordId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchBlob<{
        status: 200;
        data: Blob;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 415;
        data: ProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/document/${encodeURIComponent(recordId)}/pdf`, {
        ...opts
    }));
}
/**
 * Reads a file by recordId
 */
export function getDocumentByRecordIdFile(recordId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchBlob<{
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/document/${encodeURIComponent(recordId)}/file`, {
        ...opts
    }));
}
/**
 * Creates a calculation email document
 */
export function postCaseByCaseIdCalculationemail(caseId: string, createEmailDocument?: CreateEmailDocument, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/case/${encodeURIComponent(caseId)}/calculationemail`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createEmailDocument
    })));
}
/**
 * Creates an email document
 */
export function postCaseByCaseIdEmail(caseId: string, sentEmail?: SentEmail, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/case/${encodeURIComponent(caseId)}/email`, oazapfts.json({
        ...opts,
        method: "POST",
        body: sentEmail
    })));
}
/**
 * Creates an email document, but with no DocumentParty-entities created
 */
export function postCaseByCaseIdEmailNoparties(caseId: string, sentEmail?: SentEmail, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: string;
    }>(`/case/${encodeURIComponent(caseId)}/email/noparties`, oazapfts.json({
        ...opts,
        method: "POST",
        body: sentEmail
    })));
}
/**
 * Creates a document
 */
export function postCaseByCaseIdDocument(caseId: string, createDocument?: CreateDocument, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: string;
    }>(`/case/${encodeURIComponent(caseId)}/document`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createDocument
    })));
}
/**
 * Saves event receipt
 */
export function postCaseByCaseIdEventreceipt(caseId: string, eventReceipt?: EventReceipt, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/case/${encodeURIComponent(caseId)}/eventreceipt`, oazapfts.json({
        ...opts,
        method: "POST",
        body: eventReceipt
    })));
}
/**
 * Updates case responsible
 */
export function putCaseByCaseIdResponsible(caseId: string, body?: PhaseResponsible[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/case/${encodeURIComponent(caseId)}/responsible`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    })));
}
/**
 * Updates case handler
 */
export function putCaseByCaseIdCasehandlerAndCaseHandler(caseId: string, caseHandler: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/case/${encodeURIComponent(caseId)}/casehandler/${encodeURIComponent(caseHandler)}`, {
        ...opts,
        method: "PUT"
    }));
}
/**
 * Updates executing org unit, if called without executinOrgUnitCode the case's executingUnit is set to blank
 */
export function putCaseByCaseIdExecutingorgunitAndExecutinOrgUnitCode(caseId: string, executinOrgUnitCode: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/case/${encodeURIComponent(caseId)}/executingorgunit/${encodeURIComponent(executinOrgUnitCode)}`, {
        ...opts,
        method: "PUT"
    }));
}
/**
 * Updates executing org unit, if called without executinOrgUnitCode the case's executingUnit is set to blank
 */
export function putCaseByCaseIdExecutingorgunit(caseId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/case/${encodeURIComponent(caseId)}/executingorgunit`, {
        ...opts,
        method: "PUT"
    }));
}
/**
 * Soft delete records
 */
export function postDocumentSoftdelete(body?: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText("/document/softdelete", oazapfts.json({
        ...opts,
        method: "POST",
        body
    })));
}
/**
 * Get a list of usent payment notices
 */
export function getDocumentUnsentpaymentnotices(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseRecordIdPair[];
    }>("/document/unsentpaymentnotices", {
        ...opts
    }));
}
/**
 * Create new documentparties on documents. Accepts a list of changes.
 */
export function postDocumentParty(body?: DocumentPartyChange[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText("/document/party", oazapfts.json({
        ...opts,
        method: "POST",
        body
    })));
}
export function postDocumentSendstatus(documentSendStatusChange?: DocumentSendStatusChange, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText("/document/sendstatus", oazapfts.json({
        ...opts,
        method: "POST",
        body: documentSendStatusChange
    })));
}
/**
 * Returns role information
 */
export function getRoles(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            controller?: string;
            roles?: string;
            endpoint?: string;
            httpMethods?: string;
        };
    }>("/_roles", {
        ...opts
    }));
}
/**
 * Returns health information
 */
export function getHealth(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            status?: string;
            TotalDuration?: string;
            entries?: object;
        };
    }>("/_health", {
        ...opts
    }));
}
export enum CaseStateCode {
    Pending = "Pending",
    PendingCustomer = "PendingCustomer",
    PendingAllocation = "PendingAllocation",
    BeingProcessed = "BeingProcessed",
    FullyProcessed = "FullyProcessed",
    New = "New",
    NotImplemented = "NotImplemented"
}
export enum EventDescriptionCode {
    Conversion = "Conversion",
    FullRedemption = "FullRedemption",
    ExtraRepayment = "ExtraRepayment",
    NewLoan = "NewLoan",
    Redemption = "Redemption"
}
export enum CloseReasonCode {
    AgreementRejected = "AgreementRejected",
    CalculationRejected = "CalculationRejected",
    Done = "Done",
    Error = "Error",
    NoReturn = "NoReturn"
}
export enum CasePhaseCode {
    AgreementConfirmation = "AgreementConfirmation",
    AgreementGeneration = "AgreementGeneration",
    Calculation = "Calculation",
    Execution = "Execution",
    IndividualPricing = "IndividualPricing"
}
export enum PartyRoleCode {
    ContactPerson = "ContactPerson",
    Customer = "Customer",
    CaseParty = "CaseParty",
    AdvisRecipient = "AdvisRecipient"
}
export enum CasePhaseState {
    Cancelled = "Cancelled",
    Done = "Done",
    NotApplicable = "NotApplicable",
    NotInitialized = "NotInitialized",
    Pending = "Pending",
    PendingAllocation = "PendingAllocation",
    PendingCustomer = "PendingCustomer",
    Progress = "Progress",
    Rejected = "Rejected",
    Restarted = "Restarted"
}
export enum NotificationGroup {
    CommonNotification = "CommonNotification",
    PersonalNotification = "PersonalNotification"
}
export enum NotificationTrigger {
    CompensationAmountFixed = "CompensationAmountFixed",
    CustomerResponseReceived = "CustomerResponseReceived",
    Priced = "Priced",
    FullRedemptionRejected = "FullRedemptionRejected",
    Rejected = "Rejected",
    Replied = "Replied",
    Sent = "Sent",
    ExtraPaymentRejected = "ExtraPaymentRejected"
}
export enum ChangeMethodCode {
    ReducedTimeToMaturity = "ReducedTimeToMaturity",
    ReducedPayment = "ReducedPayment"
}
export enum CurrencyCode {
    Dkk = "DKK",
    Eur = "EUR",
    Gbp = "GBP",
    Usd = "USD",
    Chf = "CHF",
    Undefined = "Undefined"
}
export enum NoteType {
    CloseReason = "CloseReason",
    IndvidualPricing = "IndvidualPricing",
    Rejection = "Rejection"
}
export enum RecordType {
    Deleted = "Deleted",
    Document = "Document",
    Incoming = "Incoming",
    Internal = "Internal",
    Outgoing = "Outgoing",
    IrrelevantForLoanPortal = "IrrelevantForLoanPortal",
    Advis = "Advis"
}
export enum RecordGroup {
    AddendumToAgreement = "AddendumToAgreement",
    AgreementDocument = "AgreementDocument",
    Calculation = "Calculation",
    CustomerConfimation = "CustomerConfimation",
    Statement = "Statement",
    Other = "Other",
    IrrelevantForLoanPortal = "IrrelevantForLoanPortal",
    Advis = "Advis"
}
export enum QueryFilter {
    Active = "Active",
    InActive = "InActive",
    All = "All"
}
export enum SendStatus {
    New = "New",
    DoNotSend = "DoNotSend",
    Sent = "Sent"
}
export enum DocumentParty {
    Recipient = "Recipient",
    Sender = "Sender",
    CopyRecipient = "CopyRecipient",
    Party = "Party",
    Author = "Author"
}

// #region INJECTED PATHS
/* The generated output is not a direct result of oazapfts' default behavior.
* It instead incorporates our custom code, which adds path placeholder properties for MSW mocking, into the output of oazapfts. */
// {{{

const _getContractByContractNumberCasesPath = () => defaults.baseUrl + '/contract/:contractNumber/cases';
Object.defineProperty(getContractByContractNumberCases, 'path', { get: _getContractByContractNumberCasesPath, set: _getContractByContractNumberCasesPath });
/** @path `/contract/:contractNumber/cases` */ 
getContractByContractNumberCases.path = getContractByContractNumberCases.path satisfies string;


const _getContractByContractNumberPath = () => defaults.baseUrl + '/contract/:contractNumber';
Object.defineProperty(getContractByContractNumber, 'path', { get: _getContractByContractNumberPath, set: _getContractByContractNumberPath });
/** @path `/contract/:contractNumber` */ 
getContractByContractNumber.path = getContractByContractNumber.path satisfies string;


const _getContactByCompanyContactIdCasesPath = () => defaults.baseUrl + '/contact/:companyContactId/cases';
Object.defineProperty(getContactByCompanyContactIdCases, 'path', { get: _getContactByCompanyContactIdCasesPath, set: _getContactByCompanyContactIdCasesPath });
/** @path `/contact/:companyContactId/cases` */ 
getContactByCompanyContactIdCases.path = getContactByCompanyContactIdCases.path satisfies string;


const _getCaseByCaseIdPath = () => defaults.baseUrl + '/case/:caseId';
Object.defineProperty(getCaseByCaseId, 'path', { get: _getCaseByCaseIdPath, set: _getCaseByCaseIdPath });
/** @path `/case/:caseId` */ 
getCaseByCaseId.path = getCaseByCaseId.path satisfies string;


const _getCasesPath = () => defaults.baseUrl + '/cases';
Object.defineProperty(getCases, 'path', { get: _getCasesPath, set: _getCasesPath });
/** @path `/cases` */ 
getCases.path = getCases.path satisfies string;


const _getCaseByCompanyIdCasesPath = () => defaults.baseUrl + '/case/:companyId/cases';
Object.defineProperty(getCaseByCompanyIdCases, 'path', { get: _getCaseByCompanyIdCasesPath, set: _getCaseByCompanyIdCasesPath });
/** @path `/case/:companyId/cases` */ 
getCaseByCompanyIdCases.path = getCaseByCompanyIdCases.path satisfies string;


const _getCaseByCompanyIdCaseslitePath = () => defaults.baseUrl + '/case/:companyId/caseslite';
Object.defineProperty(getCaseByCompanyIdCaseslite, 'path', { get: _getCaseByCompanyIdCaseslitePath, set: _getCaseByCompanyIdCaseslitePath });
/** @path `/case/:companyId/caseslite` */ 
getCaseByCompanyIdCaseslite.path = getCaseByCompanyIdCaseslite.path satisfies string;


const _getCaseByCaseIdAssigneesPath = () => defaults.baseUrl + '/case/:caseId/assignees';
Object.defineProperty(getCaseByCaseIdAssignees, 'path', { get: _getCaseByCaseIdAssigneesPath, set: _getCaseByCaseIdAssigneesPath });
/** @path `/case/:caseId/assignees` */ 
getCaseByCaseIdAssignees.path = getCaseByCaseIdAssignees.path satisfies string;


const _postCaseLoansgotactivecaseByEventDescriptionPath = () => defaults.baseUrl + '/case/loansgotactivecase/:eventDescription';
Object.defineProperty(postCaseLoansgotactivecaseByEventDescription, 'path', { get: _postCaseLoansgotactivecaseByEventDescriptionPath, set: _postCaseLoansgotactivecaseByEventDescriptionPath });
/** @path `/case/loansgotactivecase/:eventDescription` */ 
postCaseLoansgotactivecaseByEventDescription.path = postCaseLoansgotactivecaseByEventDescription.path satisfies string;


const _getCasesActivePath = () => defaults.baseUrl + '/cases/active';
Object.defineProperty(getCasesActive, 'path', { get: _getCasesActivePath, set: _getCasesActivePath });
/** @path `/cases/active` */ 
getCasesActive.path = getCasesActive.path satisfies string;


const _getCasesActivelitePath = () => defaults.baseUrl + '/cases/activelite';
Object.defineProperty(getCasesActivelite, 'path', { get: _getCasesActivelitePath, set: _getCasesActivelitePath });
/** @path `/cases/activelite` */ 
getCasesActivelite.path = getCasesActivelite.path satisfies string;


const _getCaseEventdescriptionsPath = () => defaults.baseUrl + '/case/eventdescriptions';
Object.defineProperty(getCaseEventdescriptions, 'path', { get: _getCaseEventdescriptionsPath, set: _getCaseEventdescriptionsPath });
/** @path `/case/eventdescriptions` */ 
getCaseEventdescriptions.path = getCaseEventdescriptions.path satisfies string;


const _getNextcalculationdisplayidByCaseIdPath = () => defaults.baseUrl + '/nextcalculationdisplayid/:caseId';
Object.defineProperty(getNextcalculationdisplayidByCaseId, 'path', { get: _getNextcalculationdisplayidByCaseIdPath, set: _getNextcalculationdisplayidByCaseIdPath });
/** @path `/nextcalculationdisplayid/:caseId` */ 
getNextcalculationdisplayidByCaseId.path = getNextcalculationdisplayidByCaseId.path satisfies string;


const _postCalculationstatusByCalculationPathPath = () => defaults.baseUrl + '/calculationstatus/:calculationPath';
Object.defineProperty(postCalculationstatusByCalculationPath, 'path', { get: _postCalculationstatusByCalculationPathPath, set: _postCalculationstatusByCalculationPathPath });
/** @path `/calculationstatus/:calculationPath` */ 
postCalculationstatusByCalculationPath.path = postCalculationstatusByCalculationPath.path satisfies string;


const _postCalculationByCalculationPathUpdatePath = () => defaults.baseUrl + '/calculation/:calculationPath/update';
Object.defineProperty(postCalculationByCalculationPathUpdate, 'path', { get: _postCalculationByCalculationPathUpdatePath, set: _postCalculationByCalculationPathUpdatePath });
/** @path `/calculation/:calculationPath/update` */ 
postCalculationByCalculationPathUpdate.path = postCalculationByCalculationPathUpdate.path satisfies string;


const _postCasePath = () => defaults.baseUrl + '/case';
Object.defineProperty(postCase, 'path', { get: _postCasePath, set: _postCasePath });
/** @path `/case` */ 
postCase.path = postCase.path satisfies string;


const _getContactByCompanyContactIdPath = () => defaults.baseUrl + '/contact/:companyContactId';
Object.defineProperty(getContactByCompanyContactId, 'path', { get: _getContactByCompanyContactIdPath, set: _getContactByCompanyContactIdPath });
/** @path `/contact/:companyContactId` */ 
getContactByCompanyContactId.path = getContactByCompanyContactId.path satisfies string;


const _putContactByCaseIdAndNewCompanyContactIdPath = () => defaults.baseUrl + '/contact/:caseId/:newCompanyContactId';
Object.defineProperty(putContactByCaseIdAndNewCompanyContactId, 'path', { get: _putContactByCaseIdAndNewCompanyContactIdPath, set: _putContactByCaseIdAndNewCompanyContactIdPath });
/** @path `/contact/:caseId/:newCompanyContactId` */ 
putContactByCaseIdAndNewCompanyContactId.path = putContactByCaseIdAndNewCompanyContactId.path satisfies string;


const _getCaseClosereasonsByCaseIdPath = () => defaults.baseUrl + '/case/closereasons/:caseId';
Object.defineProperty(getCaseClosereasonsByCaseId, 'path', { get: _getCaseClosereasonsByCaseIdPath, set: _getCaseClosereasonsByCaseIdPath });
/** @path `/case/closereasons/:caseId` */ 
getCaseClosereasonsByCaseId.path = getCaseClosereasonsByCaseId.path satisfies string;


const _postCaseByCaseIdCalculationPath = () => defaults.baseUrl + '/case/:caseId/calculation';
Object.defineProperty(postCaseByCaseIdCalculation, 'path', { get: _postCaseByCaseIdCalculationPath, set: _postCaseByCaseIdCalculationPath });
/** @path `/case/:caseId/calculation` */ 
postCaseByCaseIdCalculation.path = postCaseByCaseIdCalculation.path satisfies string;


const _putCaseByCaseIdClosePath = () => defaults.baseUrl + '/case/:caseId/close';
Object.defineProperty(putCaseByCaseIdClose, 'path', { get: _putCaseByCaseIdClosePath, set: _putCaseByCaseIdClosePath });
/** @path `/case/:caseId/close` */ 
putCaseByCaseIdClose.path = putCaseByCaseIdClose.path satisfies string;


const _putCaseByCaseIdPhasestatesPath = () => defaults.baseUrl + '/case/:caseId/phasestates';
Object.defineProperty(putCaseByCaseIdPhasestates, 'path', { get: _putCaseByCaseIdPhasestatesPath, set: _putCaseByCaseIdPhasestatesPath });
/** @path `/case/:caseId/phasestates` */ 
putCaseByCaseIdPhasestates.path = putCaseByCaseIdPhasestates.path satisfies string;


const _putCaseByCaseIdCasestatePath = () => defaults.baseUrl + '/case/:caseId/casestate';
Object.defineProperty(putCaseByCaseIdCasestate, 'path', { get: _putCaseByCaseIdCasestatePath, set: _putCaseByCaseIdCasestatePath });
/** @path `/case/:caseId/casestate` */ 
putCaseByCaseIdCasestate.path = putCaseByCaseIdCasestate.path satisfies string;


const _getContactByContactIdNotificationsPath = () => defaults.baseUrl + '/contact/:contactId/notifications';
Object.defineProperty(getContactByContactIdNotifications, 'path', { get: _getContactByContactIdNotificationsPath, set: _getContactByContactIdNotificationsPath });
/** @path `/contact/:contactId/notifications` */ 
getContactByContactIdNotifications.path = getContactByContactIdNotifications.path satisfies string;


const _postNotificationByNotificationIdMarkasreadPath = () => defaults.baseUrl + '/notification/:notificationId/markasread';
Object.defineProperty(postNotificationByNotificationIdMarkasread, 'path', { get: _postNotificationByNotificationIdMarkasreadPath, set: _postNotificationByNotificationIdMarkasreadPath });
/** @path `/notification/:notificationId/markasread` */ 
postNotificationByNotificationIdMarkasread.path = postNotificationByNotificationIdMarkasread.path satisfies string;


const _putCalculationByCaseIdDisplayidAndDisplayIdPath = () => defaults.baseUrl + '/calculation/:caseId/displayid/:displayId';
Object.defineProperty(putCalculationByCaseIdDisplayidAndDisplayId, 'path', { get: _putCalculationByCaseIdDisplayidAndDisplayIdPath, set: _putCalculationByCaseIdDisplayidAndDisplayIdPath });
/** @path `/calculation/:caseId/displayid/:displayId` */ 
putCalculationByCaseIdDisplayidAndDisplayId.path = putCalculationByCaseIdDisplayidAndDisplayId.path satisfies string;


const _postCaseNotePath = () => defaults.baseUrl + '/case/note';
Object.defineProperty(postCaseNote, 'path', { get: _postCaseNotePath, set: _postCaseNotePath });
/** @path `/case/note` */ 
postCaseNote.path = postCaseNote.path satisfies string;


const _putCaseNotePath = () => defaults.baseUrl + '/case/note';
Object.defineProperty(putCaseNote, 'path', { get: _putCaseNotePath, set: _putCaseNotePath });
/** @path `/case/note` */ 
putCaseNote.path = putCaseNote.path satisfies string;


const _putCalculationByCaseIdCancelagreementcandidatePath = () => defaults.baseUrl + '/calculation/:caseId/cancelagreementcandidate';
Object.defineProperty(putCalculationByCaseIdCancelagreementcandidate, 'path', { get: _putCalculationByCaseIdCancelagreementcandidatePath, set: _putCalculationByCaseIdCancelagreementcandidatePath });
/** @path `/calculation/:caseId/cancelagreementcandidate` */ 
putCalculationByCaseIdCancelagreementcandidate.path = putCalculationByCaseIdCancelagreementcandidate.path satisfies string;


const _postCalculationByCalculationPathMarkasagreementcandidatePath = () => defaults.baseUrl + '/calculation/:calculationPath/markasagreementcandidate';
Object.defineProperty(postCalculationByCalculationPathMarkasagreementcandidate, 'path', { get: _postCalculationByCalculationPathMarkasagreementcandidatePath, set: _postCalculationByCalculationPathMarkasagreementcandidatePath });
/** @path `/calculation/:calculationPath/markasagreementcandidate` */ 
postCalculationByCalculationPathMarkasagreementcandidate.path = postCalculationByCalculationPathMarkasagreementcandidate.path satisfies string;


const _putDocumentActivePath = () => defaults.baseUrl + '/document/active';
Object.defineProperty(putDocumentActive, 'path', { get: _putDocumentActivePath, set: _putDocumentActivePath });
/** @path `/document/active` */ 
putDocumentActive.path = putDocumentActive.path satisfies string;


const _putCalculationActivePath = () => defaults.baseUrl + '/calculation/active';
Object.defineProperty(putCalculationActive, 'path', { get: _putCalculationActivePath, set: _putCalculationActivePath });
/** @path `/calculation/active` */ 
putCalculationActive.path = putCalculationActive.path satisfies string;


const _putCaseByCaseIdPhaseAndCasePhaseCodePath = () => defaults.baseUrl + '/case/:caseId/phase/:casePhaseCode';
Object.defineProperty(putCaseByCaseIdPhaseAndCasePhaseCode, 'path', { get: _putCaseByCaseIdPhaseAndCasePhaseCodePath, set: _putCaseByCaseIdPhaseAndCasePhaseCodePath });
/** @path `/case/:caseId/phase/:casePhaseCode` */ 
putCaseByCaseIdPhaseAndCasePhaseCode.path = putCaseByCaseIdPhaseAndCasePhaseCode.path satisfies string;


const _putCaseByCaseIdEventDescriptionAndEventTypePath = () => defaults.baseUrl + '/case/:caseId/eventDescription/:eventType';
Object.defineProperty(putCaseByCaseIdEventDescriptionAndEventType, 'path', { get: _putCaseByCaseIdEventDescriptionAndEventTypePath, set: _putCaseByCaseIdEventDescriptionAndEventTypePath });
/** @path `/case/:caseId/eventDescription/:eventType` */ 
putCaseByCaseIdEventDescriptionAndEventType.path = putCaseByCaseIdEventDescriptionAndEventType.path satisfies string;


const _putCaseByCaseIdDisplayamountAndAmountPath = () => defaults.baseUrl + '/case/:caseId/displayamount/:amount';
Object.defineProperty(putCaseByCaseIdDisplayamountAndAmount, 'path', { get: _putCaseByCaseIdDisplayamountAndAmountPath, set: _putCaseByCaseIdDisplayamountAndAmountPath });
/** @path `/case/:caseId/displayamount/:amount` */ 
putCaseByCaseIdDisplayamountAndAmount.path = putCaseByCaseIdDisplayamountAndAmount.path satisfies string;


const _putDocumentLabelPath = () => defaults.baseUrl + '/document/label';
Object.defineProperty(putDocumentLabel, 'path', { get: _putDocumentLabelPath, set: _putDocumentLabelPath });
/** @path `/document/label` */ 
putDocumentLabel.path = putDocumentLabel.path satisfies string;


const _getDocumentByRecordIdPdfPath = () => defaults.baseUrl + '/document/:recordId/pdf';
Object.defineProperty(getDocumentByRecordIdPdf, 'path', { get: _getDocumentByRecordIdPdfPath, set: _getDocumentByRecordIdPdfPath });
/** @path `/document/:recordId/pdf` */ 
getDocumentByRecordIdPdf.path = getDocumentByRecordIdPdf.path satisfies string;


const _getDocumentByRecordIdFilePath = () => defaults.baseUrl + '/document/:recordId/file';
Object.defineProperty(getDocumentByRecordIdFile, 'path', { get: _getDocumentByRecordIdFilePath, set: _getDocumentByRecordIdFilePath });
/** @path `/document/:recordId/file` */ 
getDocumentByRecordIdFile.path = getDocumentByRecordIdFile.path satisfies string;


const _postCaseByCaseIdCalculationemailPath = () => defaults.baseUrl + '/case/:caseId/calculationemail';
Object.defineProperty(postCaseByCaseIdCalculationemail, 'path', { get: _postCaseByCaseIdCalculationemailPath, set: _postCaseByCaseIdCalculationemailPath });
/** @path `/case/:caseId/calculationemail` */ 
postCaseByCaseIdCalculationemail.path = postCaseByCaseIdCalculationemail.path satisfies string;


const _postCaseByCaseIdEmailPath = () => defaults.baseUrl + '/case/:caseId/email';
Object.defineProperty(postCaseByCaseIdEmail, 'path', { get: _postCaseByCaseIdEmailPath, set: _postCaseByCaseIdEmailPath });
/** @path `/case/:caseId/email` */ 
postCaseByCaseIdEmail.path = postCaseByCaseIdEmail.path satisfies string;


const _postCaseByCaseIdEmailNopartiesPath = () => defaults.baseUrl + '/case/:caseId/email/noparties';
Object.defineProperty(postCaseByCaseIdEmailNoparties, 'path', { get: _postCaseByCaseIdEmailNopartiesPath, set: _postCaseByCaseIdEmailNopartiesPath });
/** @path `/case/:caseId/email/noparties` */ 
postCaseByCaseIdEmailNoparties.path = postCaseByCaseIdEmailNoparties.path satisfies string;


const _postCaseByCaseIdDocumentPath = () => defaults.baseUrl + '/case/:caseId/document';
Object.defineProperty(postCaseByCaseIdDocument, 'path', { get: _postCaseByCaseIdDocumentPath, set: _postCaseByCaseIdDocumentPath });
/** @path `/case/:caseId/document` */ 
postCaseByCaseIdDocument.path = postCaseByCaseIdDocument.path satisfies string;


const _postCaseByCaseIdEventreceiptPath = () => defaults.baseUrl + '/case/:caseId/eventreceipt';
Object.defineProperty(postCaseByCaseIdEventreceipt, 'path', { get: _postCaseByCaseIdEventreceiptPath, set: _postCaseByCaseIdEventreceiptPath });
/** @path `/case/:caseId/eventreceipt` */ 
postCaseByCaseIdEventreceipt.path = postCaseByCaseIdEventreceipt.path satisfies string;


const _putCaseByCaseIdResponsiblePath = () => defaults.baseUrl + '/case/:caseId/responsible';
Object.defineProperty(putCaseByCaseIdResponsible, 'path', { get: _putCaseByCaseIdResponsiblePath, set: _putCaseByCaseIdResponsiblePath });
/** @path `/case/:caseId/responsible` */ 
putCaseByCaseIdResponsible.path = putCaseByCaseIdResponsible.path satisfies string;


const _putCaseByCaseIdCasehandlerAndCaseHandlerPath = () => defaults.baseUrl + '/case/:caseId/casehandler/:caseHandler';
Object.defineProperty(putCaseByCaseIdCasehandlerAndCaseHandler, 'path', { get: _putCaseByCaseIdCasehandlerAndCaseHandlerPath, set: _putCaseByCaseIdCasehandlerAndCaseHandlerPath });
/** @path `/case/:caseId/casehandler/:caseHandler` */ 
putCaseByCaseIdCasehandlerAndCaseHandler.path = putCaseByCaseIdCasehandlerAndCaseHandler.path satisfies string;


const _putCaseByCaseIdExecutingorgunitAndExecutinOrgUnitCodePath = () => defaults.baseUrl + '/case/:caseId/executingorgunit/:executinOrgUnitCode';
Object.defineProperty(putCaseByCaseIdExecutingorgunitAndExecutinOrgUnitCode, 'path', { get: _putCaseByCaseIdExecutingorgunitAndExecutinOrgUnitCodePath, set: _putCaseByCaseIdExecutingorgunitAndExecutinOrgUnitCodePath });
/** @path `/case/:caseId/executingorgunit/:executinOrgUnitCode` */ 
putCaseByCaseIdExecutingorgunitAndExecutinOrgUnitCode.path = putCaseByCaseIdExecutingorgunitAndExecutinOrgUnitCode.path satisfies string;


const _putCaseByCaseIdExecutingorgunitPath = () => defaults.baseUrl + '/case/:caseId/executingorgunit';
Object.defineProperty(putCaseByCaseIdExecutingorgunit, 'path', { get: _putCaseByCaseIdExecutingorgunitPath, set: _putCaseByCaseIdExecutingorgunitPath });
/** @path `/case/:caseId/executingorgunit` */ 
putCaseByCaseIdExecutingorgunit.path = putCaseByCaseIdExecutingorgunit.path satisfies string;


const _postDocumentSoftdeletePath = () => defaults.baseUrl + '/document/softdelete';
Object.defineProperty(postDocumentSoftdelete, 'path', { get: _postDocumentSoftdeletePath, set: _postDocumentSoftdeletePath });
/** @path `/document/softdelete` */ 
postDocumentSoftdelete.path = postDocumentSoftdelete.path satisfies string;


const _getDocumentUnsentpaymentnoticesPath = () => defaults.baseUrl + '/document/unsentpaymentnotices';
Object.defineProperty(getDocumentUnsentpaymentnotices, 'path', { get: _getDocumentUnsentpaymentnoticesPath, set: _getDocumentUnsentpaymentnoticesPath });
/** @path `/document/unsentpaymentnotices` */ 
getDocumentUnsentpaymentnotices.path = getDocumentUnsentpaymentnotices.path satisfies string;


const _postDocumentPartyPath = () => defaults.baseUrl + '/document/party';
Object.defineProperty(postDocumentParty, 'path', { get: _postDocumentPartyPath, set: _postDocumentPartyPath });
/** @path `/document/party` */ 
postDocumentParty.path = postDocumentParty.path satisfies string;


const _postDocumentSendstatusPath = () => defaults.baseUrl + '/document/sendstatus';
Object.defineProperty(postDocumentSendstatus, 'path', { get: _postDocumentSendstatusPath, set: _postDocumentSendstatusPath });
/** @path `/document/sendstatus` */ 
postDocumentSendstatus.path = postDocumentSendstatus.path satisfies string;


const _getRolesPath = () => defaults.baseUrl + '/_roles';
Object.defineProperty(getRoles, 'path', { get: _getRolesPath, set: _getRolesPath });
/** @path `/_roles` */ 
getRoles.path = getRoles.path satisfies string;


const _getHealthPath = () => defaults.baseUrl + '/_health';
Object.defineProperty(getHealth, 'path', { get: _getHealthPath, set: _getHealthPath });
/** @path `/_health` */ 
getHealth.path = getHealth.path satisfies string;


// }}}
 // #endregion 