/**
 * Orchestration Api
 * 1.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.RequestOpts = {
    baseUrl: "/",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type TimeMeasure = {
    years?: number;
    months?: number;
    days?: number;
};
export type AmortListResponse = {
    valueDate?: string;
    repayment?: number | null;
    interest?: number | null;
    margin?: number | null;
    additionalPayment?: number | null;
    payment?: number | null;
    notionalAmount?: number | null;
    notifiedDate?: string | null;
    knownInterest?: boolean;
    interestRateStatus?: InterestRateStatus;
};
export type LoanInfoResponse = {
    contractId?: string | null;
    companyId?: string | null;
    companyLasId?: string | null;
    principalAmount?: number;
    startDate?: string;
    loanPurpose?: string | null;
    notionalAmount?: number;
    maturityDate?: string;
    loanProduct?: ContractLoanTypeEnum;
    sustainableFramework?: SustainableFrameworkType;
    greenLoan?: boolean;
    currency?: CurrencyCode;
    redemptionValue?: number;
    interestRate?: number;
    nextFixingDate?: string | null;
    interestPaymentFrequency?: PaymentFreqEnum;
    spread?: number;
    fundingSpread?: number | null;
    agreementDate?: string;
    interestConditionExpiryDate?: string;
    timeToMaturity?: TimeMeasure;
    accountingAreaId?: string | null;
    accountingGroupId?: string | null;
    instrumentId?: string | null;
    customerSwap?: string | null;
    marginRate?: number;
    interestRatePlusSpread?: number;
    firstPayment?: number;
    loanDuration?: TimeMeasure;
    amortList?: AmortListResponse[] | null;
};
export type CompanyContactModel = {
    id?: string | null;
    fullname?: string | null;
    firstName?: string | null;
    lastname?: string | null;
    jobTitle?: string | null;
    email?: string | null;
    phone?: string | null;
    cellPhone?: string | null;
    pepStatus?: string | null;
};
export type CompanyResponsibleModel = {
    id?: string | null;
    fullname?: string | null;
    jobTitle?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phone?: string | null;
    cellPhone?: string | null;
    employeePicture?: any | null;
};
export type CompanyModel = {
    companyId?: string;
    companyResponsibleId?: string;
    companyNumber?: string | null;
    cvr?: string | null;
    legalName?: string | null;
    payerName?: string | null;
    addressLine1?: string | null;
    addressLine2?: string | null;
    postalCode?: string | null;
    city?: string | null;
    country?: string | null;
    status?: any | null;
    kycApproved?: boolean | null;
    kycExpiration?: string | null;
    accountingPeriod?: number | null;
    typeOfLiability?: string | null;
    accountingPurposes?: string | null;
    companyType?: string | null;
    companyCategory?: string | null;
    auditor?: string | null;
    auditorContact?: string | null;
    auditorEmail?: string | null;
    authorizationToForward?: string | null;
    dateOfLatestArticlesOfAssociation?: string | null;
    powerToBindTheCompany?: string | null;
    segmentation?: string | null;
    seNumber?: string | null;
    primaryContact?: CompanyContactModel;
    companyResponsible?: CompanyResponsibleModel;
};
export type CaseCalculationModel = {
    id?: number;
    calculationPath?: string | null;
    displayId?: number | null;
    isActive?: boolean;
    calculationTimestamp?: string | null;
    repayAmount?: number | null;
    eventDueDate?: string | null;
    changeMethod?: ChangeMethodCode;
    eventDescription?: EventDescriptionCode;
    currencyCode?: CurrencyCode;
    isAgreementCandidateCode?: boolean;
};
export type CasePartyModel = {
    partyName?: string | null;
    partyEmail?: string | null;
    partyRoleCode?: PartyRoleCode;
    partyRoleDescription?: string | null;
    partyPhone?: string | null;
    partyCellphone?: string | null;
    partyDisplayPhoneNumber?: string | null;
    partyIsPrimary?: boolean;
    nameType?: string | null;
    nameKey?: string | null;
    nameCode?: string | null;
    partyCRMGuid?: string | null;
    primaryPartyCVR?: string | null;
};
export type CasePhaseModel = {
    casePhaseDescription?: string | null;
    casePhaseRank?: number;
    casePhaseCode?: CasePhaseCode;
    phaseResponsibleCode?: string | null;
    phaseResponsibleName?: string | null;
    phaseParticipant1Code?: string | null;
    phaseParticipant1Name?: string | null;
    phaseParticipant2Code?: string | null;
    phaseParticipant2Name?: string | null;
    phaseStateCode?: CasePhaseState;
    phaseStateDescription?: string | null;
    phaseStateRank?: number;
};
export type CaseNoteModel = {
    caseNoteId?: string | null;
    noteTypeCode?: NoteType;
    noteTypeDescription?: string | null;
    noteText?: string | null;
    createUserName?: string | null;
    createDateTime?: string;
    updateUserName?: string | null;
    updateDateTime?: string | null;
};
export type CaseRecordModel = {
    recordId?: string | null;
    recordKey?: string | null;
    title?: string | null;
    recordExtension?: string | null;
    recordTypeDescription?: string | null;
    recordTypeCode?: RecordType;
    recordGroupDescription?: string | null;
    recordGroupCode?: RecordGroup;
    casePhaseCode?: CasePhaseCode;
    casePhaseDescription?: string | null;
    createDate?: string;
    documentHierarchyCode?: string | null;
    primaryRecordKey?: string | null;
    isActive?: boolean;
    recordSize?: number;
    postalDatetime?: string | null;
    calcId?: string | null;
};
export type NotificationModel = {
    notificationId?: string | null;
    parentNotificationId?: string | null;
    notificationGroupCode?: NotificationGroup;
    notificationGroupDescription?: string | null;
    readTime?: string | null;
    notificationTriggerDescription?: string | null;
    notificationTriggerSummary?: string | null;
    notificationGroupSummary?: string | null;
    notificationTriggerCode?: NotificationTrigger;
    recipientCode?: string | null;
    recipientSummary?: string | null;
    recipientName?: string | null;
    recipientOrgUnitCode?: string | null;
    recipientOrgUnitSummary?: string | null;
    recipientOrgUnitName?: string | null;
    actor?: string | null;
    casePhaseCode?: CasePhaseCode;
    casePhaseSummary?: string | null;
    casePhaseDescription?: string | null;
    eventCode?: string | null;
    eventDescription?: string | null;
    createDatetime?: string | null;
    updateDatetime?: string | null;
    fileKey?: string | null;
    eventSummary?: string | null;
};
export type EventReceiptModel = {
    id?: string | null;
    repayAmount?: number | null;
    eventDueDate?: string | null;
    compensationAmount?: number | null;
    timeToMaturityReduction?: TimeMeasure;
    newMaturityDate?: string | null;
    eventValueDate?: string | null;
    accruedInterest?: number | null;
    changeMethod?: ChangeMethodCode;
    newNotionalAmount?: number | null;
    paymentReductionAmount?: number | null;
    isOnTerm?: boolean;
    fileKey?: string | null;
    createDate?: string | null;
    updateDate?: string | null;
};
export type CaseModel = {
    caseId?: string | null;
    fileKey?: string | null;
    fileClassCode?: string | null;
    title?: string | null;
    contractId?: string | null;
    companyNumber?: string | null;
    caseStateDescription?: CaseStateCode;
    eventDescription?: EventDescriptionCode;
    displayAmount?: number | null;
    createDate?: string | null;
    createUserCode?: string | null;
    createUserName?: string | null;
    updateDateTime?: string | null;
    updateUserCode?: string | null;
    updateUserName?: string | null;
    closeDate?: string | null;
    closeReasonCode?: CloseReasonCode;
    closeReasonDescription?: string | null;
    caseHandlerName?: string | null;
    caseHandlerCode?: string | null;
    caseHandlerEmail?: string | null;
    caseHandlerPhone?: string | null;
    executingOrgUnitCode?: string | null;
    casePhaseCode?: CasePhaseCode;
    calculations?: CaseCalculationModel[] | null;
    parties?: CasePartyModel[] | null;
    phases?: CasePhaseModel[] | null;
    notes?: CaseNoteModel[] | null;
    caseRecords?: CaseRecordModel[] | null;
    notifications?: NotificationModel[] | null;
    eventReceipt?: EventReceiptModel;
};
export type ContractLoanInfo = {
    loanInfo?: LoanInfoResponse;
    company?: CompanyModel;
    cases?: CaseModel[] | null;
};
export type ErrorResponse = {
    errorMessage?: string[] | null;
};
export type ConfigSettings = {
    minimumAmount?: number;
    maximumAmount?: number;
    contractUpdateIntervalMinutes?: number;
    loanProducts?: string[] | null;
    eventDescription?: string[] | null;
    compensationAmountVariancePercentage?: number;
    departmentRoleMap?: {
        [key: string]: string[];
    } | null;
};
export type AllocationModel = {
    allocationId?: number;
    caseId?: string | null;
    allocationName?: string | null;
    companyNumber?: string | null;
    originalAmount?: number | null;
    remainingAmount?: number | null;
    remainingAmountAdjusted?: number | null;
    legalLoanPurposeCodes?: LegalLoanPurposeCode[] | null;
    stateCode?: AllocationStateCode;
    maxTimeToMaturity?: number;
    approveDate?: string;
    createDate?: string;
    updateDate?: string;
    activeAgreementIds?: string[] | null;
    caseRecords?: CaseRecordModel[] | null;
};
export type EligibleLoanResponse = {
    contractId?: string | null;
    loanProduct?: ContractLoanTypeEnum;
    sustainableFramework?: SustainableFrameworkType;
    notionalAmount?: number;
};
export type CreateCaseParameters = {
    eventType?: EventDescriptionCode;
    contractId?: string | null;
    companyContactId?: string | null;
};
export type CalculationInfoResponse = {
    contractId?: string | null;
    companyId?: string | null;
    companyLasId?: string | null;
    principalAmount?: number;
    startDate?: string;
    loanPurpose?: string | null;
    notionalAmount?: number;
    maturityDate?: string;
    loanProduct?: ContractLoanTypeEnum;
    sustainableFramework?: SustainableFrameworkType;
    greenLoan?: boolean;
    currency?: CurrencyCode;
    redemptionValue?: number;
    interestRate?: number;
    nextFixingDate?: string | null;
    interestPaymentFrequency?: PaymentFreqEnum;
    spread?: number;
    agreementDate?: string;
    interestConditionExpiryDate?: string;
    timeToMaturity?: TimeMeasure;
    accountingAreaId?: string | null;
    accountingGroupId?: string | null;
    instrumentId?: string | null;
    customerSwap?: string | null;
    marginRate?: number;
    interestRatePlusSpread?: number;
    firstPayment?: number;
    loanDuration?: TimeMeasure;
    amortList?: AmortListResponse[] | null;
    eventDescription?: EventDescriptionCode;
    repayAmount?: number;
    eventDueDate?: string | null;
    eventValueDate?: string | null;
    changeMethod?: ChangeMethodCode;
    calculationDateTime?: string;
    notionalAmountOnValueDate?: number;
    accruedInterest?: number;
    compensationAmount?: number;
    compensationAmountCalculated?: number;
    compensationAmountReported?: number | null;
    preEventMaturityDate?: string;
    postEventMaturityDate?: string;
    paymentReduction?: number;
    timeToMaturityReduction?: TimeMeasure;
    isOnTerm?: boolean;
    markedDataDateTime?: string;
    firstInterestDate?: string | null;
    lastInterestDate?: string | null;
    firstInstalmentFreeRepaymentDate?: string | null;
    calculationPath?: string | null;
    initialCosts?: number;
    amortizationProfile?: AmortizationProfile;
    fixedInterestYears?: number | null;
    fixedInterestPeriodType?: FixedInterestPeriodType;
    leasingFee?: number | null;
    scrap?: number | null;
    numberOfPayments?: number;
    fixedFirstRepayment?: number | null;
    fundingSpread?: number | null;
};
export type CreateCaseResponse = {
    "case"?: CaseModel;
    calculation?: CalculationInfoResponse;
};
export type CreateLoanDraftParameters = {
    companyNumber?: string | null;
    loanPurpose?: string | null;
    allocationName?: string | null;
    companyContactId?: string | null;
};
export type LoanDraftResponse = {
    loanCase?: CaseModel;
    allocation?: AllocationModel;
    loanInfo?: LoanInfoResponse;
};
export type CalculateLoanEvent = {
    eventDescription?: EventDescriptionCode;
    amount?: number | null;
    appliedDate?: string;
    changeMethod?: ChangeMethodCode;
};
export type CalculationResponse = {
    calculation?: CalculationInfoResponse;
    displayId?: number | null;
    calculationPath?: string | null;
};
export type UpdateCalculationParameters = {
    calculationPath: string | null;
    eventDescription?: EventDescriptionCode;
};
export type UpdateCalculationCompensationAmountParameters = {
    calculationPath: string | null;
    compensationAmount: number;
};
export type DocumentLabelRequest = {
    caseId: string | null;
    recordId: string | null;
    setDocumentLabeling: boolean;
};
export type AgreementConclusionParameters = {
    calculationPath: string | null;
    noteText?: string | null;
};
export type RedemptionStatusResponse = {
    data2LASID?: number;
    status?: RedemptionStatusEnum;
    message?: string | null;
    kreditAdvis?: number[] | null;
};
export type CreateCalculationDocumentRequest = {
    calculationPath: string;
    fileExtension: FileExtensionEnum;
};
export type StoredDocument = {
    title?: string | null;
    recordExtension?: string | null;
    calculationPath?: string | null;
    createDate?: string;
    pathToStorage?: string | null;
};
export type CreateCaseCalculationDocumentRequest = {
    calculationPath: string;
    fileExtension: FileExtensionEnum;
    displayId: number;
    caseId: string;
    contractId: string;
};
export type CreateContractDocumentRequest = {
    contractId: string;
    loanProduct: ContractLoanTypeEnum;
    fileExtension: FileExtensionEnum;
    fromYear?: number | null;
    toYear?: number | null;
};
export type CreateEmailRequest = {
    caseId: string;
    calculationPathsWithDisplayId: {
        [key: string]: number;
    };
    templateName: DocumentTemplateEnum;
    notificationId?: string | null;
    contractId?: string | null;
};
export type StoredEmail = {
    subject?: string | null;
    bodyDocument?: StoredDocument;
    attachments?: StoredDocument[] | null;
    pathToStorage?: string | null;
    sentDateTime?: string | null;
};
export type SendCalculationEmailParameters = {
    email?: StoredEmail;
    caseId?: string | null;
    contractId?: string | null;
};
export type SendAddendumToAgreementParameters = {
    caseId?: string | null;
};
export type NewLoanRequest = {
    principalValueDate: string;
    interestPaymentFrequency: PaymentFreqEnum;
    principalAmount: number;
    loanProduct: ContractLoanTypeEnum;
    amortizationProfile: AmortizationProfile;
    maturityDateUnadjusted: string;
    fixedInterestPeriodType?: FixedInterestPeriodType;
    fixedInterestYears?: number | null;
    firstPayDateUnadjusted?: string;
    instalmentFreeTerms?: number;
};
export type RenewInterestAgreementRequest = {
    fixedInterestPeriodType?: FixedInterestPeriodType;
    fixedInterestYears?: number | null;
};
export type NewLeasingRequest = {
    principalValueDate: string;
    interestPaymentFrequency: PaymentFreqEnum;
    principalAmount: number;
    loanProduct: ContractLoanTypeEnum;
    scrap?: number;
    numberOfPayments: number;
    leasingFee: number;
    fixedFirstRepayment?: number | null;
};
export type CreateLoanPortfolioRequest = {
    companyId: string;
    relation: RelationEnum;
    date?: string | null;
};
/**
 * Returns role information across all API's
 */
export function getRolesAll(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: ContractLoanInfo;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/_roles/all", {
        ...opts
    }));
}
/**
 * Get configuration settings
 */
export function getConfig(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: ConfigSettings;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/config", {
        ...opts
    }));
}
/**
 * Get loan data by contract number
 */
export function getLoandataByContractNumber(contractNumber: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: ContractLoanInfo;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/loandata/${encodeURIComponent(contractNumber)}`, {
        ...opts
    }));
}
/**
 * Get all available allocations(bevillinger) for a customer by customerId(guid)
 */
export function getLoandataByCompanyNumberAllocations(companyNumber: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: AllocationModel[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/loandata/${encodeURIComponent(companyNumber)}/allocations`, {
        ...opts
    }));
}
/**
 * Get Allocation by id
 */
export function getAllocationByAllocationId(allocationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: AllocationModel;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/allocation/${encodeURIComponent(allocationId)}`, {
        ...opts
    }));
}
/**
 * Get a list of loans eligible for the event supplied in eventDescription
 */
export function getLoandataByCompanyIdEligibleloansAndEventDescription(companyId: string, eventDescription: EventDescriptionCode, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: EligibleLoanResponse[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/loandata/${encodeURIComponent(companyId)}/eligibleloans/${encodeURIComponent(eventDescription)}`, {
        ...opts
    }));
}
/**
 * Create case
 */
export function postCase(createCaseParameters?: CreateCaseParameters, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CreateCaseResponse;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/case", oazapfts.json({
        ...opts,
        method: "POST",
        body: createCaseParameters
    })));
}
/**
 * Create loan draft
 */
export function postLoandraft(createLoanDraftParameters?: CreateLoanDraftParameters, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: LoanDraftResponse;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/loandraft", oazapfts.json({
        ...opts,
        method: "POST",
        body: createLoanDraftParameters
    })));
}
/**
 * Create calculation
 */
export function postCaseByCaseIdCreatecalculationAndContractId(contractId: string, caseId: string, calculateLoanEvent?: CalculateLoanEvent, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CalculationResponse;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/case/${encodeURIComponent(caseId)}/createcalculation/${encodeURIComponent(contractId)}`, oazapfts.json({
        ...opts,
        method: "POST",
        body: calculateLoanEvent
    })));
}
/**
 * Set the current phase on a case back to calculation
 */
export function postCaseByCaseIdRestartcalculationphase(caseId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModel;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/case/${encodeURIComponent(caseId)}/restartcalculationphase`, {
        ...opts,
        method: "POST"
    }));
}
/**
 * Update calculation takes a calculation path for the original calculation and an EventDescription, nullable if the calculation is not related to an Event
 */
export function postCalculationUpdate(updateCalculationParameters?: UpdateCalculationParameters, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CalculationResponse;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 409;
        data: string;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/calculation/update", oazapfts.json({
        ...opts,
        method: "POST",
        body: updateCalculationParameters
    })));
}
/**
 * Update calculation compensation amount, creates a new updated calculation in WZ and start the agreement execution on the calculation marked as agreement candidate
 */
export function postCaseByCaseIdStartagreementexecutionwithcompensationamount(caseId: string, updateCalculationCompensationAmountParameters?: UpdateCalculationCompensationAmountParameters, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModel;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 409;
        data: string;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/case/${encodeURIComponent(caseId)}/startagreementexecutionwithcompensationamount`, oazapfts.json({
        ...opts,
        method: "POST",
        body: updateCalculationCompensationAmountParameters
    })));
}
/**
 * Update contact person on case to the contact with the CRMGuid given in newCompanyContactId, if success returns the updated caseModel
 */
export function putContactByCaseIdAndNewCompanyContactId(caseId: string, newCompanyContactId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModel;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/contact/${encodeURIComponent(caseId)}/${encodeURIComponent(newCompanyContactId)}`, {
        ...opts,
        method: "PUT"
    }));
}
/**
 * Label an email document as the customers confirmation with an updated recordGroupCode and casePhaseCode, can also be used to remove labeling
 */
export function putDocumentLabel(documentLabelRequest?: DocumentLabelRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModel;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/document/label", oazapfts.json({
        ...opts,
        method: "PUT",
        body: documentLabelRequest
    })));
}
/**
 * Agreement conclusion
 */
export function postCaseByCaseIdAgreement(caseId: string, agreementConclusionParameters?: AgreementConclusionParameters, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModel;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 409;
        data: string;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/case/${encodeURIComponent(caseId)}/agreement`, oazapfts.json({
        ...opts,
        method: "POST",
        body: agreementConclusionParameters
    })));
}
/**
 * Notify finance
 */
export function postCaseByCaseIdNotifyfinance(caseId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModel;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/case/${encodeURIComponent(caseId)}/notifyfinance`, {
        ...opts,
        method: "POST"
    }));
}
/**
 * Start agreement execution
 */
export function postCaseByCaseIdStartagreementexecution(caseId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModel;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 409;
        data: string;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/case/${encodeURIComponent(caseId)}/startagreementexecution`, {
        ...opts,
        method: "POST"
    }));
}
/**
 * Finish agreement execution. Saves event receipt, updates execution phase status and closes the case
 */
export function postCaseByCaseIdFinishagreementexecution(caseId: string, redemptionStatusResponse?: RedemptionStatusResponse, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 409;
        data: string;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/case/${encodeURIComponent(caseId)}/finishagreementexecution`, oazapfts.json({
        ...opts,
        method: "POST",
        body: redemptionStatusResponse
    })));
}
/**
 * Reject pricing. Changes states and save note
 */
export function postCaseByCaseIdRejectpricing(caseId: string, { note }: {
    note?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModel;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/case/${encodeURIComponent(caseId)}/rejectpricing${QS.query(QS.explode({
        note
    }))}`, {
        ...opts,
        method: "POST"
    }));
}
/**
 * Initiate KYC flow for case. Returns KYC id.
 */
export function postCaseByCaseIdInitiatekycAndCompanyId(caseId: string, companyId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: string;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/case/${encodeURIComponent(caseId)}/initiatekyc/${encodeURIComponent(companyId)}`, {
        ...opts,
        method: "POST"
    }));
}
/**
 * Create calculation document using DocuMotor. Returns storage account path for document created with Templafy template.
 */
export function postCreatecalculationdocument(createCalculationDocumentRequest?: CreateCalculationDocumentRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: StoredDocument;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/createcalculationdocument", oazapfts.json({
        ...opts,
        method: "POST",
        body: createCalculationDocumentRequest
    })));
}
/**
 * Create calculation document for a case using DocuMotor. Returns storage account path for document created with Templafy template.
 */
export function postCreatecasecalculationdocument(createCaseCalculationDocumentRequest?: CreateCaseCalculationDocumentRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: StoredDocument;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/createcasecalculationdocument", oazapfts.json({
        ...opts,
        method: "POST",
        body: createCaseCalculationDocumentRequest
    })));
}
/**
 * Create contract document using DocuMotor. Returns storage account path for document created with Templafy template.<br/> The optional year parameters will filter the list of payments.
 */
export function postCreatecontractdocument(createContractDocumentRequest?: CreateContractDocumentRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: StoredDocument;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/createcontractdocument", oazapfts.json({
        ...opts,
        method: "POST",
        body: createContractDocumentRequest
    })));
}
/**
 * Create Payment Notice in Documotor with data from LAS, saving the result in Workzone. Returns the ID of the saved document in Workzone.<br/>
 */
export function postCreatepaymentnotice({ noticeId }: {
    noticeId?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: string;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/createpaymentnotice${QS.query(QS.explode({
        noticeId
    }))}`, {
        ...opts,
        method: "POST"
    }));
}
/**
 * Create email and calculation documents to attach.
 */
export function postCreateemail(createEmailRequest?: CreateEmailRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: StoredEmail;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/createemail", oazapfts.json({
        ...opts,
        method: "POST",
        body: createEmailRequest
    })));
}
/**
 * Send email with calculation documents.
 */
export function postSendcalculationmail(sendCalculationEmailParameters?: SendCalculationEmailParameters, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/sendcalculationmail", oazapfts.json({
        ...opts,
        method: "POST",
        body: sendCalculationEmailParameters
    })));
}
/**
 * Send addendum to agreement email.
 */
export function postSendaddendumtoagreementmail(sendAddendumToAgreementParameters?: SendAddendumToAgreementParameters, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModel;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/sendaddendumtoagreementmail", oazapfts.json({
        ...opts,
        method: "POST",
        body: sendAddendumToAgreementParameters
    })));
}
/**
 * Send payment notice document to case parties.
 */
export function postSendpaymentnotice({ caseId, documentId }: {
    caseId?: string;
    documentId?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModel;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/sendpaymentnotice${QS.query(QS.explode({
        caseId,
        documentId
    }))}`, {
        ...opts,
        method: "POST"
    }));
}
/**
 * new loan
 */
export function postCalculationByCalculationPathNewloan(calculationPath: string, newLoanRequest?: NewLoanRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CalculationInfoResponse;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/calculation/${encodeURIComponent(calculationPath)}/newloan`, oazapfts.json({
        ...opts,
        method: "POST",
        body: newLoanRequest
    })));
}
/**
 * renew interest agreement
 */
export function postCalculationByContractIdRenewinterestagreement(contractId: string, renewInterestAgreementRequest?: RenewInterestAgreementRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CalculationInfoResponse;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/calculation/${encodeURIComponent(contractId)}/renewinterestagreement`, oazapfts.json({
        ...opts,
        method: "POST",
        body: renewInterestAgreementRequest
    })));
}
/**
 * new leasing calculation
 */
export function postCalculationByCalculationPathNewleasing(calculationPath: string, newLeasingRequest?: NewLeasingRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CalculationInfoResponse;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/calculation/${encodeURIComponent(calculationPath)}/newleasing`, oazapfts.json({
        ...opts,
        method: "POST",
        body: newLeasingRequest
    })));
}
/**
 * Update the case handler to the currently logged in user.
 */
export function putCaseByCaseIdUpdatecasehandler(caseId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CaseModel;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/case/${encodeURIComponent(caseId)}/updatecasehandler`, {
        ...opts,
        method: "PUT"
    }));
}
/**
 * Create a customers loan portfolio on a given date to excel.
 */
export function postCreateloanportfoliodocument(createLoanPortfolioRequest?: CreateLoanPortfolioRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: StoredDocument;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/createloanportfoliodocument", oazapfts.json({
        ...opts,
        method: "POST",
        body: createLoanPortfolioRequest
    })));
}
/**
 * Returns role information
 */
export function getRoles(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            controller?: string;
            roles?: string;
            endpoint?: string;
            httpMethods?: string;
        };
    }>("/_roles", {
        ...opts
    }));
}
/**
 * Returns health information
 */
export function getHealth(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            status?: string;
            TotalDuration?: string;
            entries?: object;
        };
    }>("/_health", {
        ...opts
    }));
}
export enum ContractLoanTypeEnum {
    NurUdlan = "NUR_udl\u00E5n",
    UdlanModelB = "Udl\u00E5n_Model_B",
    KKvar = "KKvar",
    KKmax = "KKmax",
    KKbasis = "KKbasis",
    KKcibor = "KKcibor",
    KKcita = "KKcita",
    KKfast = "KKfast",
    K4Udlan = "K4_udl\u00E5n",
    AiKontant = "AI_kontant",
    Rentetilpasningslan = "Rentetilpasningsl\u00E5n",
    Indekslan = "Indeksl\u00E5n",
    Obligationslan = "Obligationsl\u00E5n",
    AiLan = "AI_l\u00E5n",
    Swap = "Swap",
    Renteswap = "Renteswap",
    Valutaswap = "Valutaswap",
    ValutaTermin = "ValutaTermin",
    ValutaSpot = "ValutaSpot",
    KontantlaanAiKontant = "Kontantlaan_AI_kontant",
    KontantlaanK4Udlan = "Kontantlaan_K4_udl\u00E5n",
    Byggekredit = "Byggekredit",
    UnDefined = "UnDefined"
}
export enum SustainableFrameworkType {
    Gbf2017 = "GBF2017",
    Gbf2022 = "GBF2022",
    None = "None"
}
export enum CurrencyCode {
    Dkk = "DKK",
    Eur = "EUR",
    Gbp = "GBP",
    Usd = "USD",
    Chf = "CHF",
    Undefined = "Undefined"
}
export enum PaymentFreqEnum {
    UnDefined = "UnDefined",
    M1 = "M1",
    M2 = "M2",
    M3 = "M3",
    M4 = "M4",
    M6 = "M6",
    M12 = "M12"
}
export enum InterestRateStatus {
    Fixed = "Fixed",
    Estimated = "Estimated",
    BeforeInterestConditionExpiryDate = "BeforeInterestConditionExpiryDate",
    AfterInterestConditionExpiryDate = "AfterInterestConditionExpiryDate"
}
export enum CaseStateCode {
    Pending = "Pending",
    BeingProcessed = "BeingProcessed",
    FullyProcessed = "FullyProcessed",
    New = "New",
    NotImplemented = "NotImplemented"
}
export enum EventDescriptionCode {
    Conversion = "Conversion",
    FullRedemption = "FullRedemption",
    ExtraRepayment = "ExtraRepayment",
    NewLoan = "NewLoan",
    Redemption = "Redemption"
}
export enum CloseReasonCode {
    AgreementRejected = "AgreementRejected",
    CalculationRejected = "CalculationRejected",
    Done = "Done",
    Error = "Error",
    NoReturn = "NoReturn"
}
export enum CasePhaseCode {
    AgreementConfirmation = "AgreementConfirmation",
    AgreementGeneration = "AgreementGeneration",
    Calculation = "Calculation",
    Execution = "Execution",
    IndividualPricing = "IndividualPricing"
}
export enum ChangeMethodCode {
    ReducedTimeToMaturity = "ReducedTimeToMaturity",
    ReducedPayment = "ReducedPayment"
}
export enum PartyRoleCode {
    ContactPerson = "ContactPerson",
    Customer = "Customer",
    CaseParty = "CaseParty",
    AdvisRecipient = "AdvisRecipient"
}
export enum CasePhaseState {
    Cancelled = "Cancelled",
    Done = "Done",
    NotApplicable = "NotApplicable",
    NotInitialized = "NotInitialized",
    Pending = "Pending",
    Progress = "Progress",
    Rejected = "Rejected",
    Restarted = "Restarted"
}
export enum NoteType {
    CloseReason = "CloseReason",
    IndvidualPricing = "IndvidualPricing",
    Rejection = "Rejection"
}
export enum RecordType {
    Deleted = "Deleted",
    Document = "Document",
    Incoming = "Incoming",
    Internal = "Internal",
    Outgoing = "Outgoing",
    IrrelevantForLoanPortal = "IrrelevantForLoanPortal"
}
export enum RecordGroup {
    AddendumToAgreement = "AddendumToAgreement",
    AgreementDocument = "AgreementDocument",
    Calculation = "Calculation",
    CustomerConfimation = "CustomerConfimation",
    Statement = "Statement",
    Other = "Other",
    IrrelevantForLoanPortal = "IrrelevantForLoanPortal",
    Advis = "Advis"
}
export enum NotificationGroup {
    CommonNotification = "CommonNotification",
    PersonalNotification = "PersonalNotification"
}
export enum NotificationTrigger {
    CompensationAmountFixed = "CompensationAmountFixed",
    CustomerResponseReceived = "CustomerResponseReceived",
    Priced = "Priced",
    FullRedemptionRejected = "FullRedemptionRejected",
    Rejected = "Rejected",
    Replied = "Replied",
    Sent = "Sent",
    ExtraPaymentRejected = "ExtraPaymentRejected"
}
export enum LegalLoanPurposeCode {
    Undefined = "Undefined"
}
export enum AllocationStateCode {
    Approved = "Approved",
    Rejected = "Rejected",
    Awaiting = "Awaiting"
}
export enum AmortizationProfile {
    Bullet = "Bullet",
    Annuity = "Annuity",
    Serial = "Serial",
    Custom = "Custom",
    UnDefined = "UnDefined"
}
export enum FixedInterestPeriodType {
    Cheapest = "Cheapest",
    ToMaturity = "ToMaturity",
    Years = "Years",
    UnDefined = "UnDefined"
}
export enum RedemptionStatusEnum {
    NotStarted = "NotStarted",
    Ok = "OK",
    Error = "Error",
    Running = "Running",
    NotRelevant = "NotRelevant",
    Undefined = "Undefined"
}
export enum FileExtensionEnum {
    Pdf = "Pdf",
    Csv = "Csv"
}
export enum DocumentTemplateEnum {
    EventCalculationExcel = "EventCalculationExcel",
    EventCalculationPdf = "EventCalculationPdf",
    CreditEventCalculationExcel = "CreditEventCalculationExcel",
    CreditEventCalculationPdf = "CreditEventCalculationPdf",
    StandaloneCalculationPdf = "StandaloneCalculationPdf",
    StandaloneCalculationExcel = "StandaloneCalculationExcel",
    AmortListExcel = "AmortListExcel",
    AmortListPdf = "AmortListPdf",
    PostingListExcel = "PostingListExcel",
    PostingListPdf = "PostingListPdf",
    PortfolioOwnerExcel = "PortfolioOwnerExcel",
    PortfolioGuaranteePartnershipExcel = "PortfolioGuaranteePartnershipExcel",
    DocToHtml = "DocToHtml",
    EmailCalcRedemption = "EmailCalcRedemption",
    EmailCalcNewLoan = "EmailCalcNewLoan",
    EmailAgrConf = "EmailAgrConf",
    EmailRequestFinIndividualPricing = "EmailRequestFinIndividualPricing",
    EmailAutomaticCrediting = "EmailAutomaticCrediting",
    EmailReceipt = "EmailReceipt",
    EmailInfoBackFin = "EmailInfoBackFin",
    EmailInfoCaseHandlerChange = "EmailInfoCaseHandlerChange",
    NewLoanCalculationPdf = "NewLoanCalculationPdf",
    NewLoanCalculationExcel = "NewLoanCalculationExcel",
    EmailPaymentNotice = "EmailPaymentNotice"
}
export enum RelationEnum {
    Owner = "Owner",
    Guarantee = "Guarantee",
    PartnershipLiability = "PartnershipLiability",
    BillReceiver = "BillReceiver",
    Undefined = "Undefined"
}

// #region INJECTED PATHS
/* The generated output is not a direct result of oazapfts' default behavior.
* It instead incorporates our custom code, which adds path placeholder properties for MSW mocking, into the output of oazapfts. */
// {{{

const _getRolesAllPath = () => defaults.baseUrl + '/_roles/all';
Object.defineProperty(getRolesAll, 'path', { get: _getRolesAllPath, set: _getRolesAllPath });
/** @path `/_roles/all` */ 
getRolesAll.path = getRolesAll.path satisfies string;


const _getConfigPath = () => defaults.baseUrl + '/config';
Object.defineProperty(getConfig, 'path', { get: _getConfigPath, set: _getConfigPath });
/** @path `/config` */ 
getConfig.path = getConfig.path satisfies string;


const _getLoandataByContractNumberPath = () => defaults.baseUrl + '/loandata/:contractNumber';
Object.defineProperty(getLoandataByContractNumber, 'path', { get: _getLoandataByContractNumberPath, set: _getLoandataByContractNumberPath });
/** @path `/loandata/:contractNumber` */ 
getLoandataByContractNumber.path = getLoandataByContractNumber.path satisfies string;


const _getLoandataByCompanyNumberAllocationsPath = () => defaults.baseUrl + '/loandata/:companyNumber/allocations';
Object.defineProperty(getLoandataByCompanyNumberAllocations, 'path', { get: _getLoandataByCompanyNumberAllocationsPath, set: _getLoandataByCompanyNumberAllocationsPath });
/** @path `/loandata/:companyNumber/allocations` */ 
getLoandataByCompanyNumberAllocations.path = getLoandataByCompanyNumberAllocations.path satisfies string;


const _getAllocationByAllocationIdPath = () => defaults.baseUrl + '/allocation/:allocationId';
Object.defineProperty(getAllocationByAllocationId, 'path', { get: _getAllocationByAllocationIdPath, set: _getAllocationByAllocationIdPath });
/** @path `/allocation/:allocationId` */ 
getAllocationByAllocationId.path = getAllocationByAllocationId.path satisfies string;


const _getLoandataByCompanyIdEligibleloansAndEventDescriptionPath = () => defaults.baseUrl + '/loandata/:companyId/eligibleloans/:eventDescription';
Object.defineProperty(getLoandataByCompanyIdEligibleloansAndEventDescription, 'path', { get: _getLoandataByCompanyIdEligibleloansAndEventDescriptionPath, set: _getLoandataByCompanyIdEligibleloansAndEventDescriptionPath });
/** @path `/loandata/:companyId/eligibleloans/:eventDescription` */ 
getLoandataByCompanyIdEligibleloansAndEventDescription.path = getLoandataByCompanyIdEligibleloansAndEventDescription.path satisfies string;


const _postCasePath = () => defaults.baseUrl + '/case';
Object.defineProperty(postCase, 'path', { get: _postCasePath, set: _postCasePath });
/** @path `/case` */ 
postCase.path = postCase.path satisfies string;


const _postLoandraftPath = () => defaults.baseUrl + '/loandraft';
Object.defineProperty(postLoandraft, 'path', { get: _postLoandraftPath, set: _postLoandraftPath });
/** @path `/loandraft` */ 
postLoandraft.path = postLoandraft.path satisfies string;


const _postCaseByCaseIdCreatecalculationAndContractIdPath = () => defaults.baseUrl + '/case/:caseId/createcalculation/:contractId';
Object.defineProperty(postCaseByCaseIdCreatecalculationAndContractId, 'path', { get: _postCaseByCaseIdCreatecalculationAndContractIdPath, set: _postCaseByCaseIdCreatecalculationAndContractIdPath });
/** @path `/case/:caseId/createcalculation/:contractId` */ 
postCaseByCaseIdCreatecalculationAndContractId.path = postCaseByCaseIdCreatecalculationAndContractId.path satisfies string;


const _postCaseByCaseIdRestartcalculationphasePath = () => defaults.baseUrl + '/case/:caseId/restartcalculationphase';
Object.defineProperty(postCaseByCaseIdRestartcalculationphase, 'path', { get: _postCaseByCaseIdRestartcalculationphasePath, set: _postCaseByCaseIdRestartcalculationphasePath });
/** @path `/case/:caseId/restartcalculationphase` */ 
postCaseByCaseIdRestartcalculationphase.path = postCaseByCaseIdRestartcalculationphase.path satisfies string;


const _postCalculationUpdatePath = () => defaults.baseUrl + '/calculation/update';
Object.defineProperty(postCalculationUpdate, 'path', { get: _postCalculationUpdatePath, set: _postCalculationUpdatePath });
/** @path `/calculation/update` */ 
postCalculationUpdate.path = postCalculationUpdate.path satisfies string;


const _postCaseByCaseIdStartagreementexecutionwithcompensationamountPath = () => defaults.baseUrl + '/case/:caseId/startagreementexecutionwithcompensationamount';
Object.defineProperty(postCaseByCaseIdStartagreementexecutionwithcompensationamount, 'path', { get: _postCaseByCaseIdStartagreementexecutionwithcompensationamountPath, set: _postCaseByCaseIdStartagreementexecutionwithcompensationamountPath });
/** @path `/case/:caseId/startagreementexecutionwithcompensationamount` */ 
postCaseByCaseIdStartagreementexecutionwithcompensationamount.path = postCaseByCaseIdStartagreementexecutionwithcompensationamount.path satisfies string;


const _putContactByCaseIdAndNewCompanyContactIdPath = () => defaults.baseUrl + '/contact/:caseId/:newCompanyContactId';
Object.defineProperty(putContactByCaseIdAndNewCompanyContactId, 'path', { get: _putContactByCaseIdAndNewCompanyContactIdPath, set: _putContactByCaseIdAndNewCompanyContactIdPath });
/** @path `/contact/:caseId/:newCompanyContactId` */ 
putContactByCaseIdAndNewCompanyContactId.path = putContactByCaseIdAndNewCompanyContactId.path satisfies string;


const _putDocumentLabelPath = () => defaults.baseUrl + '/document/label';
Object.defineProperty(putDocumentLabel, 'path', { get: _putDocumentLabelPath, set: _putDocumentLabelPath });
/** @path `/document/label` */ 
putDocumentLabel.path = putDocumentLabel.path satisfies string;


const _postCaseByCaseIdAgreementPath = () => defaults.baseUrl + '/case/:caseId/agreement';
Object.defineProperty(postCaseByCaseIdAgreement, 'path', { get: _postCaseByCaseIdAgreementPath, set: _postCaseByCaseIdAgreementPath });
/** @path `/case/:caseId/agreement` */ 
postCaseByCaseIdAgreement.path = postCaseByCaseIdAgreement.path satisfies string;


const _postCaseByCaseIdNotifyfinancePath = () => defaults.baseUrl + '/case/:caseId/notifyfinance';
Object.defineProperty(postCaseByCaseIdNotifyfinance, 'path', { get: _postCaseByCaseIdNotifyfinancePath, set: _postCaseByCaseIdNotifyfinancePath });
/** @path `/case/:caseId/notifyfinance` */ 
postCaseByCaseIdNotifyfinance.path = postCaseByCaseIdNotifyfinance.path satisfies string;


const _postCaseByCaseIdStartagreementexecutionPath = () => defaults.baseUrl + '/case/:caseId/startagreementexecution';
Object.defineProperty(postCaseByCaseIdStartagreementexecution, 'path', { get: _postCaseByCaseIdStartagreementexecutionPath, set: _postCaseByCaseIdStartagreementexecutionPath });
/** @path `/case/:caseId/startagreementexecution` */ 
postCaseByCaseIdStartagreementexecution.path = postCaseByCaseIdStartagreementexecution.path satisfies string;


const _postCaseByCaseIdFinishagreementexecutionPath = () => defaults.baseUrl + '/case/:caseId/finishagreementexecution';
Object.defineProperty(postCaseByCaseIdFinishagreementexecution, 'path', { get: _postCaseByCaseIdFinishagreementexecutionPath, set: _postCaseByCaseIdFinishagreementexecutionPath });
/** @path `/case/:caseId/finishagreementexecution` */ 
postCaseByCaseIdFinishagreementexecution.path = postCaseByCaseIdFinishagreementexecution.path satisfies string;


const _postCaseByCaseIdRejectpricingPath = () => defaults.baseUrl + '/case/:caseId/rejectpricing';
Object.defineProperty(postCaseByCaseIdRejectpricing, 'path', { get: _postCaseByCaseIdRejectpricingPath, set: _postCaseByCaseIdRejectpricingPath });
/** @path `/case/:caseId/rejectpricing` */ 
postCaseByCaseIdRejectpricing.path = postCaseByCaseIdRejectpricing.path satisfies string;


const _postCaseByCaseIdInitiatekycAndCompanyIdPath = () => defaults.baseUrl + '/case/:caseId/initiatekyc/:companyId';
Object.defineProperty(postCaseByCaseIdInitiatekycAndCompanyId, 'path', { get: _postCaseByCaseIdInitiatekycAndCompanyIdPath, set: _postCaseByCaseIdInitiatekycAndCompanyIdPath });
/** @path `/case/:caseId/initiatekyc/:companyId` */ 
postCaseByCaseIdInitiatekycAndCompanyId.path = postCaseByCaseIdInitiatekycAndCompanyId.path satisfies string;


const _postCreatecalculationdocumentPath = () => defaults.baseUrl + '/createcalculationdocument';
Object.defineProperty(postCreatecalculationdocument, 'path', { get: _postCreatecalculationdocumentPath, set: _postCreatecalculationdocumentPath });
/** @path `/createcalculationdocument` */ 
postCreatecalculationdocument.path = postCreatecalculationdocument.path satisfies string;


const _postCreatecasecalculationdocumentPath = () => defaults.baseUrl + '/createcasecalculationdocument';
Object.defineProperty(postCreatecasecalculationdocument, 'path', { get: _postCreatecasecalculationdocumentPath, set: _postCreatecasecalculationdocumentPath });
/** @path `/createcasecalculationdocument` */ 
postCreatecasecalculationdocument.path = postCreatecasecalculationdocument.path satisfies string;


const _postCreatecontractdocumentPath = () => defaults.baseUrl + '/createcontractdocument';
Object.defineProperty(postCreatecontractdocument, 'path', { get: _postCreatecontractdocumentPath, set: _postCreatecontractdocumentPath });
/** @path `/createcontractdocument` */ 
postCreatecontractdocument.path = postCreatecontractdocument.path satisfies string;


const _postCreatepaymentnoticePath = () => defaults.baseUrl + '/createpaymentnotice';
Object.defineProperty(postCreatepaymentnotice, 'path', { get: _postCreatepaymentnoticePath, set: _postCreatepaymentnoticePath });
/** @path `/createpaymentnotice` */ 
postCreatepaymentnotice.path = postCreatepaymentnotice.path satisfies string;


const _postCreateemailPath = () => defaults.baseUrl + '/createemail';
Object.defineProperty(postCreateemail, 'path', { get: _postCreateemailPath, set: _postCreateemailPath });
/** @path `/createemail` */ 
postCreateemail.path = postCreateemail.path satisfies string;


const _postSendcalculationmailPath = () => defaults.baseUrl + '/sendcalculationmail';
Object.defineProperty(postSendcalculationmail, 'path', { get: _postSendcalculationmailPath, set: _postSendcalculationmailPath });
/** @path `/sendcalculationmail` */ 
postSendcalculationmail.path = postSendcalculationmail.path satisfies string;


const _postSendaddendumtoagreementmailPath = () => defaults.baseUrl + '/sendaddendumtoagreementmail';
Object.defineProperty(postSendaddendumtoagreementmail, 'path', { get: _postSendaddendumtoagreementmailPath, set: _postSendaddendumtoagreementmailPath });
/** @path `/sendaddendumtoagreementmail` */ 
postSendaddendumtoagreementmail.path = postSendaddendumtoagreementmail.path satisfies string;


const _postSendpaymentnoticePath = () => defaults.baseUrl + '/sendpaymentnotice';
Object.defineProperty(postSendpaymentnotice, 'path', { get: _postSendpaymentnoticePath, set: _postSendpaymentnoticePath });
/** @path `/sendpaymentnotice` */ 
postSendpaymentnotice.path = postSendpaymentnotice.path satisfies string;


const _postCalculationByCalculationPathNewloanPath = () => defaults.baseUrl + '/calculation/:calculationPath/newloan';
Object.defineProperty(postCalculationByCalculationPathNewloan, 'path', { get: _postCalculationByCalculationPathNewloanPath, set: _postCalculationByCalculationPathNewloanPath });
/** @path `/calculation/:calculationPath/newloan` */ 
postCalculationByCalculationPathNewloan.path = postCalculationByCalculationPathNewloan.path satisfies string;


const _postCalculationByContractIdRenewinterestagreementPath = () => defaults.baseUrl + '/calculation/:contractId/renewinterestagreement';
Object.defineProperty(postCalculationByContractIdRenewinterestagreement, 'path', { get: _postCalculationByContractIdRenewinterestagreementPath, set: _postCalculationByContractIdRenewinterestagreementPath });
/** @path `/calculation/:contractId/renewinterestagreement` */ 
postCalculationByContractIdRenewinterestagreement.path = postCalculationByContractIdRenewinterestagreement.path satisfies string;


const _postCalculationByCalculationPathNewleasingPath = () => defaults.baseUrl + '/calculation/:calculationPath/newleasing';
Object.defineProperty(postCalculationByCalculationPathNewleasing, 'path', { get: _postCalculationByCalculationPathNewleasingPath, set: _postCalculationByCalculationPathNewleasingPath });
/** @path `/calculation/:calculationPath/newleasing` */ 
postCalculationByCalculationPathNewleasing.path = postCalculationByCalculationPathNewleasing.path satisfies string;


const _putCaseByCaseIdUpdatecasehandlerPath = () => defaults.baseUrl + '/case/:caseId/updatecasehandler';
Object.defineProperty(putCaseByCaseIdUpdatecasehandler, 'path', { get: _putCaseByCaseIdUpdatecasehandlerPath, set: _putCaseByCaseIdUpdatecasehandlerPath });
/** @path `/case/:caseId/updatecasehandler` */ 
putCaseByCaseIdUpdatecasehandler.path = putCaseByCaseIdUpdatecasehandler.path satisfies string;


const _postCreateloanportfoliodocumentPath = () => defaults.baseUrl + '/createloanportfoliodocument';
Object.defineProperty(postCreateloanportfoliodocument, 'path', { get: _postCreateloanportfoliodocumentPath, set: _postCreateloanportfoliodocumentPath });
/** @path `/createloanportfoliodocument` */ 
postCreateloanportfoliodocument.path = postCreateloanportfoliodocument.path satisfies string;


const _getRolesPath = () => defaults.baseUrl + '/_roles';
Object.defineProperty(getRoles, 'path', { get: _getRolesPath, set: _getRolesPath });
/** @path `/_roles` */ 
getRoles.path = getRoles.path satisfies string;


const _getHealthPath = () => defaults.baseUrl + '/_health';
Object.defineProperty(getHealth, 'path', { get: _getHealthPath, set: _getHealthPath });
/** @path `/_health` */ 
getHealth.path = getHealth.path satisfies string;


// }}}
 // #endregion 